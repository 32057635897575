import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import AllTitles from "../components/titles/AllTitles";
import Footer from "../components/Footer";

const CausesPage = () => {
    return (
        <Layout pageTitle="JCI India Foundation | All Titles">
            <NavOne />
            <PageHeader title="Titles" />
            <AllTitles />
            <Footer />
        </Layout>
    );
};

export default CausesPage;
