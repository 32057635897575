import React from "react";
import { Link } from "gatsby";

const ShortTitles = props => {
    return (
        <div className="blog-content">
            <div className={props.blogContent.BlogClassName}>
                <div className="blog-img">
                    <img src={props.blogContent.ImagePath} alt="" />
                </div>
                <div className="blog-inner-content">
                    <h3 className="blog__title">
                        <Link to={props.blogContent.TitleLink}>{props.blogContent.Title}</Link>
                    </h3>
                    <p className="blog__desc">
                        {props.blogContent.Description}
                    </p>
                    <p className="blog__list">
                        <strong> <i className="fa fa-inr"></i> {props.blogContent.Amount} </strong>
                    </p>
                    {/* <ul className="blog__list">
                        {props.blogContent.Feature.map(item => (
                            <li>
                                <i className={item.IconName}></i> {item.Feature} <span>{item.FeatureCount}</span>
                            </li>
                        ))}
                    </ul> */}
                    <Link className="theme-btn" to={props.blogContent.TitleLink}>
                        {props.blogContent.DonateButtonText}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ShortTitles;
