import React from "react";
// import { Link } from "gatsby";
import blogImage1 from "../../images/hgf.png";
import blogImage2 from "../../images/jfm.png";
import blogImage3 from "../../images/jfd.png";
import blogImage4 from "../../images/jfp.png";
import blogImage5 from "../../images/jff.png";
import blogImage6 from "../../images/jfs.png";
import blogImage7 from "../../images/rpp.png";
import blogImage8 from "../../images/jfj.png";
import blogImage9 from "../../images/jfa.png";
import blogImage10 from "../../images/jfg.png";
import ShortTitles from "./ShortTitles";

const Titles = [
  {
    "BlogClassName": "blog-item blog-item1",
    "Title": "HGF",
    "TitleLink": "/titles/hgf-title",
    "DonateButtonText": "Read More",
    "DonateButtonLink": "#none",
    "Description": "HENRY GIESSENBIER FELLOW",
    "ImagePath": blogImage1,
    "Amount": "3,000",
    "Feature": [
      {
        "IconName": "fa fa-star-half-o",
        "Feature": "Pin",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-id-card-o",
        "Feature": "Card",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-certificate",
        "Feature": "Certificate",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-gift",
        "Feature": "Special Privilege",
        "FeatureCount": "",
      }
    ]
  },
  {
    "BlogClassName": "blog-item blog-item2",
    "Title": "JFM",
    "TitleLink": "/titles/jfm-title",
    "DonateButtonText": "Read More",
    "DonateButtonLink": "#none",
    "Description": "JAYCEE FOUNDATION MEMBER",
    "ImagePath": blogImage2,
    "Amount": "5,000",
    "Feature": [
      {
        "IconName": "fa fa-star-half-o",
        "Feature": "Pin",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-id-card-o",
        "Feature": "Card",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-certificate",
        "Feature": "Certificate",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-gift",
        "Feature": "Special Privilege",
        "FeatureCount": "",
      }
    ]
  },
  {
    "BlogClassName": "blog-item blog-item3",
    "Title": "JFD",
    "TitleLink": "/titles/jfd-title",
    "DonateButtonText": "Read More",
    "DonateButtonLink": "#none",
    "Description": "JAYCEE FOUNDATION DONOR",
    "ImagePath": blogImage3,
    "Amount": "10,000",
    "Feature": [
      {
        "IconName": "fa fa-star-half-o",
        "Feature": "Pin",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-id-card-o",
        "Feature": "Card",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-certificate",
        "Feature": "Certificate",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-gift",
        "Feature": "Special Privilege",
        "FeatureCount": "",
      }
    ]
  },
  {
    "BlogClassName": "blog-item blog-item3",
    "Title": "JFA",
    "TitleLink": "/titles/jfa-title",
    "DonateButtonText": "Read More",
    "DonateButtonLink": "#none",
    "Description": "Jaycee Foundation Achiever",
    "ImagePath": blogImage9,
    "Amount": "15,000",
    "Feature": [
      {
        "IconName": "fa fa-star-half-o",
        "Feature": "Pin",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-id-card-o",
        "Feature": "Card",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-certificate",
        "Feature": "Certificate",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-gift",
        "Feature": "Special Privilege",
        "FeatureCount": "",
      }
    ]
  },
  {
    "BlogClassName": "blog-item blog-item4",
    "Title": "JFP",
    "TitleLink": "/titles/jfp-title",
    "DonateButtonText": "Read More",
    "DonateButtonLink": "#none",
    "Description": "JAYCEE FOUNDATION PATRON",
    "ImagePath": blogImage4,
    "Amount": "25,000",
    "Feature": [
      {
        "IconName": "fa fa-star-half-o",
        "Feature": "Pin",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-id-card-o",
        "Feature": "Card",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-certificate",
        "Feature": "Certificate",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-gift",
        "Feature": "Special Privilege",
        "FeatureCount": "",
      }
    ]
  },
  {
    "BlogClassName": "blog-item blog-item1",
    "Title": "JFF",
    "TitleLink": "/titles/jff-title",
    "DonateButtonText": "Read More",
    "DonateButtonLink": "#none",
    "Description": "JAYCEE FOUNDATION FELLOW",
    "ImagePath": blogImage5,
    "Amount": "50,000",
    "Feature": [
      {
        "IconName": "fa fa-star-half-o",
        "Feature": "Pin",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-id-card-o",
        "Feature": "Card",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-certificate",
        "Feature": "Certificate",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-gift",
        "Feature": "Special Privilege",
        "FeatureCount": "",
      }
    ]
  },
  {
    "BlogClassName": "blog-item blog-item2",
    "Title": "JFS",
    "TitleLink": "/titles/jfs-title",
    "DonateButtonText": "Read More",
    "DonateButtonLink": "#none",
    "Description": "JAYCEE FOUNDATION STAR",
    "ImagePath": blogImage6,
    "Amount": "1,00,000",
    "Feature": [
      {
        "IconName": "fa fa-star-half-o",
        "Feature": "Pin",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-id-card-o",
        "Feature": "Card",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-certificate",
        "Feature": "Certificate",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-gift",
        "Feature": "Special Privilege",
        "FeatureCount": "",
      }
    ]
  },
  {
    "BlogClassName": "blog-item blog-item2",
    "Title": "JFG",
    "TitleLink": "/titles/jfg-title",
    "DonateButtonText": "Read More",
    "DonateButtonLink": "#none",
    "Description": "Jaycee Foundation Gem",
    "ImagePath": blogImage10,
    "Amount": "2,00,000",
    "Feature": [
      {
        "IconName": "fa fa-star-half-o",
        "Feature": "Pin",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-id-card-o",
        "Feature": "Card",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-certificate",
        "Feature": "Certificate",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-gift",
        "Feature": "Special Privilege",
        "FeatureCount": "",
      }
    ]
  },
  {
    "BlogClassName": "blog-item blog-item3",
    "Title": "RPP",
    "TitleLink": "/titles/rpp-title",
    "DonateButtonText": "Read More",
    "DonateButtonLink": "#none",
    "Description": "RAJENDRANATH PAI PATRON",
    "ImagePath": blogImage7,
    "Amount": "3,00,000",
    "Feature": [
      {
        "IconName": "fa fa-star-half-o",
        "Feature": "Pin",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-id-card-o",
        "Feature": "Card",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-certificate",
        "Feature": "Certificate",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-gift",
        "Feature": "Special Privilege",
        "FeatureCount": "",
      }
    ]
  }, {
    "BlogClassName": "blog-item blog-item4",
    "Title": "JFJ",
    "TitleLink": "/titles/jfj-title",
    "DonateButtonText": "Read More",
    "DonateButtonLink": "#none",
    "Description": "JAYCEE FOUNDATION JEWEL",
    "ImagePath": blogImage8,
    "Amount": "5,00,000",
    "Feature": [
      {
        "IconName": "fa fa-star-half-o",
        "Feature": "Pin",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-id-card-o",
        "Feature": "Card",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-certificate",
        "Feature": "Certificate",
        "FeatureCount": "",
      },
      {
        "IconName": "fa fa-gift",
        "Feature": "Special Privilege",
        "FeatureCount": "",
      }
    ]
  }
];

var Api_url = process.env.GATSBY_API_URL;
var url = Api_url + `/api/wm/yearwise/team/members/lists`;
console.log('API_URL=' + Api_url + ', url=' + url);

const AllTitles = () => {
  return (
    <section className="causes-area causes-area2">
      <div className="container">
        <div className="row blog-content-wrap">
          {
            Titles.map(item => (
              <div className="col-lg-4">
                <ShortTitles blogContent={item} />
              </div>
            ))
          }
        </div>
      </div>
    </section>
  );
};

export default AllTitles;
